import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"

export default function Blog({ data }) {
  return (
    <Layout>
        <div className="container custMarg">
            <h4 className="mb-5">Posts</h4>

            <div className="wrapper">
            {data.allWpPost.nodes.map(node => (
              <Link to={node.slug}> 
                <div className="card mt-2 blogPost" key={node.slug}>
                {/* highlight-start */}
                <p className="mt-1 ml-2">{node.date.substring(0, 10)}</p>
                <img class="card-img-top" src={node.featuredImage.node.sourceUrl} />
                    <p className="ml-2">{node.title}</p>
                {/* highlight-end */}
                {/* <div dangerouslySetInnerHTML={{ __html: node.excerpt }} /> */}
                
                </div>
              </Link>
            ))}
          </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWpPost(sort: { fields: [date] }) {
      nodes {
        title
        excerpt
        slug
        date
        featuredImage {
        node {
          sourceUrl
        }
      }
      }
    }
  }
`